import { type ReactNode, useEffect, useRef } from 'react';

import { LoadingSteps, useLoadingSteps } from '@jsmdg/yoshi';

type LoadingWrapperProps = {
    loading: boolean;
    children: ReactNode;
    // use loadingComponent for Spinners/Skeletons
    loadingComponent: ReactNode;
    showLoadingAfter?: number;
    showLoadingForAtLeast?: number;
};

const LoadingWrapper = ({
    children,
    loading,
    loadingComponent,
    showLoadingAfter = 500,
    showLoadingForAtLeast = 500,
}: LoadingWrapperProps): ReactNode => {
    const loadingRef = useRef(false);
    const { setLoadingFinished, setLoadingStarted, step } = useLoadingSteps({
        showSpinnerTimeoutMS: showLoadingAfter,
        showSpinnerForAtLeast: showLoadingForAtLeast,
    });

    useEffect(() => {
        if (loading && !loadingRef.current) {
            setLoadingStarted();
        }

        if (!loading && loadingRef.current) {
            setLoadingFinished();
        }

        loadingRef.current = loading;
    }, [loading, setLoadingFinished, setLoadingStarted]);

    const showLoader = step === LoadingSteps.Loading || step === LoadingSteps.LongLoading;

    return showLoader ? loadingComponent : children;
};

export { LoadingWrapper };
