import {
    getAlgoliaIndex,
    ObjectType,
    type QuerySuggestionType,
    useAlgoliaResults,
} from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type QuerySuggestions } from '../../shared/types/querySuggestions';
import { AlgoliaQueryId } from '../enums/AlgoliaQueryId';
import { type HeaderContext } from '../types';

const DefaultSearchTrendsSize = 3;

type SearchTrendsReturn = {
    results: QuerySuggestions[];
    loading: boolean;
};

const useSearchTrends = (
    querySuggestionType: QuerySuggestionType,
    size = DefaultSearchTrendsSize,
): SearchTrendsReturn => {
    const { locale, tenant, algoliaConfig } = useFragmentContext<HeaderContext>();
    const indexName = getAlgoliaIndex({
        environment: algoliaConfig.environment,
        tenant,
        locale,
        querySuggestionType,
        objectType: ObjectType.QuerySuggestions,
    });

    return useAlgoliaResults<QuerySuggestions>({
        indexName,
        objectType: ObjectType.QuerySuggestions,
        search: {
            pagination: {
                offset: 0,
                size,
                currentPage: 1,
            },
        },
        id: `${querySuggestionType}-${AlgoliaQueryId.SearchTrends}`,
        options: {
            attributesToRetrieve: ['query'],
        },
    });
};

export { useSearchTrends };
