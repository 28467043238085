import classNames from 'classnames';

import { type Page, type Product, type SearchHistoryItem } from '../../../shared/types';
import { MIN_SEARCH_CHARS } from '../../constants/minSearchChars';
import { FlyoutContent } from './FlyoutContent';
import { SearchHistory } from './SearchHistory';
import styles from './SearchFlyout.module.scss';

type SearchFlyoutProps = {
    readonly searchResults: {
        products: { indexName: string; results: Product[]; queryId?: string };
        pages: { indexName?: string; results: Page[]; queryId?: string };
    };
    readonly onItemClick: (
        title: string,
        link: string,
        position?: number,
        productId?: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly searchHistory: SearchHistoryItem[];
    readonly setSearchHistory: (newHistory: SearchHistoryItem[]) => void;
    readonly addToHistory: (title: string, link?: string) => void;
    readonly overlayRef: React.RefObject<HTMLDivElement> | undefined;
    readonly isMobile?: boolean;
    readonly searchTerm?: string;
};

const SearchFlyout = ({
    addToHistory,
    isMobile = false,
    onItemClick,
    overlayRef,
    searchHistory,
    searchResults,
    searchTerm = '',
    setSearchHistory,
}: SearchFlyoutProps): JSX.Element | null => {
    const hasSearchResults =
        searchResults.products.results.length + searchResults.pages.results.length > 0;

    if (searchTerm.length >= MIN_SEARCH_CHARS && !hasSearchResults) {
        return null;
    }

    return (
        <>
            {!isMobile && (
                <div
                    className={classNames(
                        'd-flex justify-content-center justify-content-md-start align-items-start position-absolute',
                        styles.searchFlyoutBackdrop,
                    )}
                />
            )}
            <div
                className={classNames('mt-2x ml-auto mr-auto d-flex', styles.searchFlyoutOverlay, {
                    'flex-column': hasSearchResults,
                    'flex-column-reverse': !hasSearchResults,
                })}
                ref={overlayRef}
                data-testid="search-flyout"
            >
                <div
                    className={classNames(
                        'd-flex border-bottom-0 flex-column flex-md-row',
                        styles.searchFlyoutContent,
                    )}
                    data-has-suggestions={hasSearchResults}
                >
                    <FlyoutContent
                        searchResults={searchResults}
                        isMobile={isMobile}
                        onItemClick={onItemClick}
                        addToHistory={addToHistory}
                        searchTerm={searchTerm}
                    />
                </div>
                <SearchHistory
                    searchHistory={searchHistory}
                    setSearchHistory={setSearchHistory}
                    hasSuggestions={hasSearchResults}
                />
            </div>
        </>
    );
};

export { SearchFlyout };
// @loadable/component has an issue with the named export which we couldn't resolve so far.
// When using named exports it fails on the second render
// Using a default export for now until we found solution.
export default SearchFlyout; // eslint-disable-line import/no-default-export
