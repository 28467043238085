import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { QuerySuggestionType } from '@jsmdg/algolia-helpers';
import { SearchType, trackSubmitSearch } from '@jsmdg/tracking';
import { Link, LinkVariant, Skeleton, SkeletonAnimation, SkeletonVariant } from '@jsmdg/yoshi';
import { useSearchTrends } from '../../hooks/useSearchTrends';
import { LoadingWrapper } from '../LoadingWrapper/LoadingWrapper';
import styles from './SearchFlyout.module.scss';

const messages = defineMessages({
    searchTrendsTitle: {
        defaultMessage: 'Aktuelle Suchtrends',
    },
});

const sizes = {
    desktop: 7,
    mobile: 5,
};

type SearchTrendsProps = {
    readonly isMobile?: boolean;
};

const SearchTrends = ({ isMobile }: SearchTrendsProps): JSX.Element => {
    const intl = useIntl();
    const size = isMobile ? sizes.mobile : sizes.desktop;

    const { loading, results: searchTrends } = useSearchTrends(QuerySuggestionType.Product, size);

    const skeletons = Array.from({ length: size }, (_, index) => (
        <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data-testid="search-trends-skeleton"
            className="w-75 mb-2x"
        >
            <Skeleton
                animation={SkeletonAnimation.Wave}
                variant={SkeletonVariant.Text}
                height={20}
            />
        </div>
    ));

    return (
        <div
            data-testid="search-trends"
            className={classNames('py-md-3x pt-2x pb-0 pl-3x pl-xs-4x', styles.trendsWrapper)}
        >
            <div className={classNames('fw-bold mb-3x', styles.searchFlyoutTitle)}>
                {intl.formatMessage(messages.searchTrendsTitle)}
            </div>
            <div className="d-flex flex-column">
                <LoadingWrapper loading={loading} loadingComponent={skeletons}>
                    {searchTrends.map(searchTrend => (
                        <Link
                            key={searchTrend.query}
                            variant={LinkVariant.GreyQuiet}
                            href={`/search?q=${searchTrend.query}`}
                            className="mb-2x"
                            internal
                            onClick={() =>
                                trackSubmitSearch(
                                    searchTrend.query,
                                    SearchType.TrendingSearches,
                                    'not set',
                                    `/search?q=${searchTrend.query}`,
                                    0,
                                )
                            }
                        >
                            {searchTrend.query}
                        </Link>
                    ))}
                </LoadingWrapper>
            </div>
        </div>
    );
};

export { SearchTrends };
