import { type JSX } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { SearchType } from '@jsmdg/tracking';
import { type Product } from '../../../shared/types';
import { SimplifiedProductTileWrapper } from './SimplifiedProductTileWrapper';

const messages = defineMessages({
    experiencesTitle: {
        defaultMessage: 'Passende Erlebnisse',
    },
});

type ProductSearchResultsProps = {
    readonly products: { indexName: string; results: Product[]; queryId?: string };
    readonly loading?: boolean;
    readonly skeletonSize?: number;
    readonly isMobile?: boolean;
    readonly onItemClick: (
        title: string,
        link: string,
        position: number,
        productId: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly addToHistory: (title: string, link?: string) => void;
    readonly searchTerm: string;
};

const ProductSearchResults = ({
    addToHistory,
    isMobile = false,
    loading = false,
    onItemClick,
    products,
    searchTerm,
    skeletonSize = 0,
}: ProductSearchResultsProps): JSX.Element | null => {
    const intl = useIntl();

    return (
        <SimplifiedProductTileWrapper
            products={products.results}
            loading={loading}
            skeletonSize={skeletonSize}
            title={intl.formatMessage(messages.experiencesTitle)}
            showButton
            isWideView={!isMobile}
            className="pl-md-4x pt-3x pr-4x pb-md-4x pb-2x"
            onItemClick={onItemClick}
            addToHistory={addToHistory}
            searchTerm={searchTerm}
            testId="product-results"
            indexName={products.indexName}
            queryId={products.queryId}
            searchType={SearchType.MatchingSearchExperience}
        />
    );
};

export { ProductSearchResults };
