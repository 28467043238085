import { type JSX } from 'react';
import classNames from 'classnames';
import { filterXSS } from 'xss';

import { SearchType, trackSubmitSearch } from '@jsmdg/tracking';
import { Link, LinkVariant } from '@jsmdg/yoshi';
import { type Page } from '../../../shared/types';
import styles from './SearchFlyout.module.scss';

type PageSearchResultsProps = {
    readonly pages: { indexName?: string; results: Page[]; queryId?: string };
    readonly onItemClick: (
        title: string,
        link: string,
        position: number,
        pageId: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly searchTerm: string;
};

const PageSearchResults = ({
    onItemClick,
    pages,
    searchTerm,
}: PageSearchResultsProps): JSX.Element | null => (
    <div className={classNames('py-md-3x pt-2x px-3x px-xs-4x', styles.trendsWrapper)}>
        <div data-testid="page-results" className="d-flex flex-column">
            {pages.results.map((suggestion: Page, index: number) => (
                <Link
                    variant={LinkVariant.GreyQuiet}
                    key={suggestion.pageId}
                    href={suggestion.url}
                    className={classNames('mb-2x fw-semibold', styles.pageResult)}
                    onClick={() => {
                        onItemClick(
                            suggestion.title,
                            suggestion.url,
                            index + 1,
                            suggestion.pageId,
                            pages.indexName,
                            pages.queryId,
                        );
                        trackSubmitSearch(
                            suggestion.title,
                            SearchType.MatchingSearchList,
                            searchTerm,
                            suggestion.url,
                            pages.results.length || 0,
                        );
                    }}
                    internal
                >
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: filterXSS(suggestion.highlightedTitle || suggestion.title, {
                                whiteList: {
                                    span: ['class'],
                                },
                            }),
                        }}
                    />
                    {!!suggestion.numberOfProducts && (
                        <span>{` (${suggestion.numberOfProducts})`}</span>
                    )}
                </Link>
            ))}
        </div>
    </div>
);

export { PageSearchResults };
