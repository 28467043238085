import { type ReactNode } from 'react';

import { getLastSeenProductIdsStorage } from '@jsmdg/browser-storage';
import { type Page, type Product } from '../../../shared/types';
import { MIN_SEARCH_CHARS } from '../../constants/minSearchChars';
import { FlyoutResultType } from '../../enums/flyoutResultType';
import { LastSeenProducts } from './LastSeenProducts';
import { MostPopularCategories } from './MostPopularCategories';
import { MostPopularProducts } from './MostPopularProducts';
import { PageSearchResults } from './PageSearchResults';
import { ProductSearchResults } from './ProductSearchResults';
import { SearchTrends } from './SearchTrends';

type FlyoutContentProps = {
    searchResults: {
        products: { indexName: string; results: Product[]; queryId?: string };
        pages: { indexName?: string; results: Page[]; queryId?: string };
    };
    onItemClick: (
        title: string,
        link: string,
        position?: number,
        productId?: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    addToHistory: (title: string, link?: string) => void;
    isMobile?: boolean;
    searchTerm: string;
};

const FlyoutContent = ({
    addToHistory,
    isMobile,
    onItemClick,
    searchResults: { pages, products },
    searchTerm,
}: FlyoutContentProps): ReactNode => {
    const lastSeenProductIds = getLastSeenProductIdsStorage().get();
    const hasLastSeenProducts = !!lastSeenProductIds.length;
    const maxProductSize = isMobile ? 2 : 3;

    const hasPages = pages.results.length > 0;
    const hasProducts = products.results.length > 0;

    const paddingClass = hasLastSeenProducts ? 'pt-3x' : 'pt-1x pt-md-3x';

    const contentMapping = {
        [FlyoutResultType.PageResults]: (
            <PageSearchResults
                key={FlyoutResultType.PageResults}
                pages={pages}
                onItemClick={onItemClick}
                searchTerm={searchTerm}
            />
        ),
        [FlyoutResultType.ProductResults]: (
            <ProductSearchResults
                key={FlyoutResultType.ProductResults}
                products={products}
                isMobile={isMobile}
                onItemClick={onItemClick}
                addToHistory={addToHistory}
                searchTerm={searchTerm}
            />
        ),
        [FlyoutResultType.LastSeen]: (
            <LastSeenProducts
                key={FlyoutResultType.LastSeen}
                lastSeenProductIds={lastSeenProductIds.slice(0, maxProductSize)}
                onItemClick={onItemClick}
                addToHistory={addToHistory}
            />
        ),
        [FlyoutResultType.SearchTrends]: (
            <SearchTrends key={FlyoutResultType.SearchTrends} isMobile={isMobile} />
        ),
        [FlyoutResultType.PopularProducts]: (
            <MostPopularProducts
                key={FlyoutResultType.PopularProducts}
                size={maxProductSize}
                onItemClick={onItemClick}
                addToHistory={addToHistory}
            />
        ),
        [FlyoutResultType.PopularCategories]: (
            <MostPopularCategories
                key={FlyoutResultType.PopularCategories}
                className={paddingClass}
                isMobile={isMobile}
            />
        ),
    };

    const flyoutContent = [];

    // add contents when user did not type yet
    if (searchTerm.length < MIN_SEARCH_CHARS) {
        if (isMobile && hasLastSeenProducts) {
            flyoutContent.push(contentMapping[FlyoutResultType.LastSeen]);
        }

        flyoutContent.push(contentMapping[FlyoutResultType.SearchTrends]);

        if (!isMobile && hasLastSeenProducts) {
            flyoutContent.push(contentMapping[FlyoutResultType.LastSeen]);
        }

        if (!hasLastSeenProducts) {
            flyoutContent.push(contentMapping[FlyoutResultType.PopularProducts]);
        }

        flyoutContent.push(contentMapping[FlyoutResultType.PopularCategories]);

        return flyoutContent;
    }

    // add contents when user is typing
    flyoutContent.push(
        contentMapping[hasPages ? FlyoutResultType.PageResults : FlyoutResultType.SearchTrends],
    );

    if (hasProducts) {
        flyoutContent.push(contentMapping[FlyoutResultType.ProductResults]);
    } else {
        flyoutContent.push(
            contentMapping[
                hasLastSeenProducts ? FlyoutResultType.LastSeen : FlyoutResultType.PopularProducts
            ],
        );

        flyoutContent.push(contentMapping[FlyoutResultType.PopularCategories]);
    }

    return flyoutContent;
};

export { FlyoutContent };
