import { type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { SearchType, trackSubmitSearch } from '@jsmdg/tracking';
import {
    DinnerIcon,
    FlyIcon,
    GiftWrappingIcon,
    HealthIcon,
    Link,
    LinkVariant,
    SteeringWheelIcon,
    TicketsOutlineThickIcon,
    TripIcon,
    WaterSportsIcon,
} from '@jsmdg/yoshi';
import { CategoryIcon } from '../../enums/categoryIcon';
import { type HeaderContext } from '../../types';
import styles from './SearchFlyout.module.scss';

const iconMapping: Record<CategoryIcon, ReactNode> = {
    [CategoryIcon.Giftbox]: (
        <GiftWrappingIcon className={classNames('mr-0-5x', styles.categoryItem)} />
    ),
    [CategoryIcon.ValueVoucher]: (
        <TicketsOutlineThickIcon className={classNames('mr-0-5x', styles.categoryItem)} />
    ),
    [CategoryIcon.Travel]: <TripIcon className={classNames('mr-0-5x', styles.categoryItem)} />,
    [CategoryIcon.Flying]: (
        <FlyIcon className={classNames('mr-0-5x', styles.categoryItem, styles.flyIcon)} />
    ),
    [CategoryIcon.Motorpower]: (
        <SteeringWheelIcon className={classNames('mr-0-5x', styles.categoryItem)} />
    ),
    [CategoryIcon.Dinner]: <DinnerIcon className={classNames('mr-0-5x', styles.categoryItem)} />,
    [CategoryIcon.WaterSports]: (
        <WaterSportsIcon className={classNames('mr-0-5x', styles.categoryItem)} />
    ),
    [CategoryIcon.Wellness]: <HealthIcon className={classNames('mr-0-5x', styles.categoryItem)} />,
};

const messages = defineMessages({
    categoriesTitle: {
        defaultMessage: 'Beliebte Kategorien',
    },
});

const MostPopularCategories = ({
    className,
    isMobile = false,
}: {
    readonly className: string;
    readonly isMobile?: boolean;
}): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<HeaderContext>();

    const categoriesToDisplay = isMobile
        ? tenantConfig.categoryItems.slice(0, 5)
        : tenantConfig.categoryItems;

    return (
        <div
            data-testid="most-popular-categories"
            className={classNames('flex-1', className, 'p-3x px-xs-4x')}
        >
            <div className={classNames('fw-bold mb-3x', styles.searchFlyoutTitle)}>
                {intl.formatMessage(messages.categoriesTitle)}
            </div>
            <div className="d-flex flex-column">
                {categoriesToDisplay.map(category => (
                    <Link
                        key={category.title}
                        variant={LinkVariant.GreyQuiet}
                        iconLeft={iconMapping[category.icon]}
                        href={category.url}
                        className="mb-2x"
                        onClick={(e: { isTrusted: boolean }) => {
                            if (process.env.NODE_ENV !== 'test' && !e.isTrusted) return;

                            trackSubmitSearch(
                                category.title,
                                SearchType.PopularCategories,
                                'not set',
                                category.url,
                                0,
                            );
                        }}
                    >
                        {category.title}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export { MostPopularCategories };
