import { type JSX } from 'react';

import { ResponsiveFrame, Skeleton } from '@jsmdg/yoshi';
import { SkeletonAnimation, SkeletonVariant } from '@jsmdg/yoshi/dist/enums/skeleton';

type SkeletonTileProps = {
    readonly testId?: string;
};

const SkeletonTile = ({ testId }: SkeletonTileProps): JSX.Element => {
    return (
        <div data-testid={testId} className="w-100 d-flex justify-content-between mb-1-5x">
            <div className="w-50 h-100">
                <ResponsiveFrame width={100} height={60}>
                    <div className="w-100 h-100">
                        <Skeleton
                            animation={SkeletonAnimation.Wave}
                            variant={SkeletonVariant.Rect}
                            height="100%"
                        />
                    </div>
                </ResponsiveFrame>
            </div>
            <div className="d-flex flex-1 justify-content-between ml-1x py-0-5">
                <div className="d-flex flex-1 flex-column">
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Text}
                        className="w-100 mb-0-5x pr-0 pr-xs-2x"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Text}
                        className="w-75 mt-1x"
                        height={16}
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Text}
                        className="w-50 mt-1x"
                        height={16}
                    />
                </div>
            </div>
        </div>
    );
};

export { SkeletonTile };
