import { type JSX } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { SearchType } from '@jsmdg/tracking';
import { useMostPopularProducts } from '../../hooks/useMostPopularProducts';
import { SimplifiedProductTileWrapper } from './SimplifiedProductTileWrapper';

type MostPopularProductsProps = {
    readonly size: number;
    readonly onItemClick: (
        title: string,
        link: string,
        position: number,
        productId: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly addToHistory: (title: string, link?: string) => void;
};

const messages = defineMessages({
    popularExperiencesTitle: {
        defaultMessage: 'Beliebte Erlebnisse',
    },
});

const MostPopularProducts = ({
    addToHistory,
    onItemClick,
    size,
}: MostPopularProductsProps): JSX.Element | null => {
    const intl = useIntl();
    const { indexName, loading, queryId, results: products } = useMostPopularProducts(size);

    return (
        <SimplifiedProductTileWrapper
            products={products}
            loading={loading}
            skeletonSize={size}
            title={intl.formatMessage(messages.popularExperiencesTitle)}
            onItemClick={onItemClick}
            addToHistory={addToHistory}
            testId="most-popular-products"
            indexName={indexName}
            queryId={queryId}
            searchType={SearchType.PopularExperiences}
        />
    );
};

export { MostPopularProducts };
