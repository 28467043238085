import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type Product } from '../../shared/types';
import { type HeaderContext } from '../types';

const messages = defineMessages({
    onlineExperienceLocation: {
        defaultMessage: 'Online-Erlebnis',
    },
    multipleLocations: {
        defaultMessage: 'an {locationCount} Orten',
    },
});

export const useLocationLabel = (): ((product: Product) => string | undefined) => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<HeaderContext>();

    const getLocationLabel = (product: Product): string | undefined => {
        const { locations, classifications, closestLocation } = product;

        const isOnlineExperience =
            classifications?.includes('LocationOnline') &&
            locations?.some(location =>
                location?.name
                    ? tenantConfig.onlineExperienceOptions.includes(location.name)
                    : false,
            );

        const hasClosestLocation = !!closestLocation && !isOnlineExperience;

        if (!locations?.length) {
            return undefined;
        }

        if (hasClosestLocation) {
            return closestLocation.name;
        }

        if (isOnlineExperience) {
            return intl.formatMessage(messages.onlineExperienceLocation);
        }

        if (locations.length > 1) {
            return intl.formatMessage(messages.multipleLocations, {
                locationCount: locations.length,
            });
        }

        return locations[0].name;
    };

    return getLocationLabel;
};
