import { type SyntheticEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { SearchType, trackDeleteSearchHistory, trackSubmitSearch } from '@jsmdg/tracking';
import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    CloseIcon,
} from '@jsmdg/yoshi';
import { type SearchHistoryItem } from '../../../shared/types';
import styles from './SearchFlyout.module.scss';

const messages = defineMessages({
    historySearchTitle: {
        defaultMessage: 'Letzte Suchbegriffe',
    },
});

type FlyoutHistoryProps = {
    readonly searchHistory: SearchHistoryItem[];
    readonly setSearchHistory: (newHistory: SearchHistoryItem[]) => void;
    readonly hasSuggestions: boolean;
};

const SearchHistory = ({
    hasSuggestions,
    searchHistory,
    setSearchHistory,
}: FlyoutHistoryProps): JSX.Element | null => {
    const intl = useIntl();

    const removeHistoryItem = (
        removeIndex: number,
        title: string,
        event?: SyntheticEvent,
    ): void => {
        event?.stopPropagation();

        trackDeleteSearchHistory(title);

        const newSearchHistory = searchHistory.filter(
            (_historyItem, itemIndex) => itemIndex !== removeIndex,
        );

        setSearchHistory(newSearchHistory);
    };

    const selectHistoryItem = (title: string, link?: string): void => {
        trackSubmitSearch(title, SearchType.HistoryClick, title, link || '', 0);

        const newSearchHistory = searchHistory.filter(historyItem => historyItem.title !== title);
        setSearchHistory([{ title, link }, ...newSearchHistory]);

        const url = link || `/search?q=${title}`;

        window.location.href = url;
    };

    if (!searchHistory.length) {
        return null;
    }

    return (
        <div
            className={classNames(
                'd-flex align-items-md-center ml-3x ml-xs-4x mt-2x mb-0 my-md-3x  mt-md-3x flex-column flex-md-row',
                {
                    'mt-0': hasSuggestions,
                },
            )}
        >
            <div className={classNames('fw-bold mb-2x mb-md-0', styles.searchFlyoutTitle)}>
                {intl.formatMessage(messages.historySearchTitle)}
            </div>
            <div className="d-flex align-items-start m-0 flex-wrap ml-md-5x mr-xs-3x">
                {searchHistory.map((searchElement, index) => (
                    <Button
                        className="mr-1-5x mb-1x p-1x pl-2x"
                        shape={ButtonShape.Pill}
                        variant={ButtonVariant.Outlined}
                        color={ButtonColor.Grey}
                        size={ButtonSize.Small}
                        iconRight={
                            <CloseIcon
                                dataTestId={`close-icon-history-item-${index}`}
                                className={classNames(styles.closeIcon, 'ml-1x')}
                                onClick={(event?: SyntheticEvent) =>
                                    removeHistoryItem(index, searchElement.title, event)
                                }
                            />
                        }
                        onClick={() => selectHistoryItem(searchElement.title, searchElement.link)}
                        key={searchElement.title}
                    >
                        <div className={classNames('text-truncate fw-regular', styles.historyPill)}>
                            {searchElement.title}
                        </div>
                    </Button>
                ))}
            </div>
        </div>
    );
};

export { SearchHistory };
