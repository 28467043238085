import { type JSX } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { SearchType } from '@jsmdg/tracking';
import { useLastSeenProducts } from '../../hooks/useLastSeenProducts';
import { SimplifiedProductTileWrapper } from './SimplifiedProductTileWrapper';

type LastSeenProductsProps = {
    readonly lastSeenProductIds: string[];
    readonly onItemClick: (
        title: string,
        link: string,
        position: number,
        productId: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly addToHistory: (title: string, link?: string) => void;
};

const messages = defineMessages({
    lastSeenTitle: {
        defaultMessage: 'Zuletzt gesehene Erlebnisse',
    },
});

const LastSeenProducts = ({
    addToHistory,
    lastSeenProductIds,
    onItemClick,
}: LastSeenProductsProps): JSX.Element | null => {
    const intl = useIntl();
    const {
        indexName,
        loading,
        queryId,
        results: products,
    } = useLastSeenProducts(lastSeenProductIds);

    return (
        <SimplifiedProductTileWrapper
            title={intl.formatMessage(messages.lastSeenTitle)}
            products={products}
            loading={loading}
            skeletonSize={lastSeenProductIds.length}
            onItemClick={onItemClick}
            addToHistory={addToHistory}
            testId="last-seen-products"
            indexName={indexName}
            queryId={queryId}
            searchType={SearchType.LastSeenExperiences}
        />
    );
};

export { LastSeenProducts };
